
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import accountModule from '@/store/modules/accountModule';
import tasqsListModule from '@/store/modules/tasqsListModule';
import scheduleModule from '@/store/modules/scheduleModule';
import workflowModule from '@/store/modules/workflowModule';
import DropdownPlaceholder from '@/components/tasqsCommon/DropdownPlaceholder.vue';
import TasqsSorting from '@/components/tasqsCommon/TasqsSorting.vue';
import { getConfigEnv, sleep } from '@/utils/helpers';
import { sort } from 'fast-sort';
import { VERTICAL_LIST_SORT_DETAULT_VALUE } from '@/lib/constants';
import userProfileModule from '@/store/modules/userProfileModule';
import assetsModule from '@/store/modules/assetsModule';

@Component({
  components: {
    DropdownPlaceholder,
    TasqsSorting,
  },
})
export default class VerticalListFiltersAndSort extends Vue {
  @Prop({ type: Boolean, default: false }) showFiltersPopup!: boolean;
  @Prop({ type: Array }) waitingOnOptions!: any[];
  @Prop({ type: String }) filterId!: string;
  @Prop({ type: String, default: '' }) filterName!: string;
  @Prop({ type: Number, default: 0 }) totalResults!: string;
  @Prop({
    type: Object,
    required: false,
    default: () => ({
      typesOptions: [],
      selectedUsernames: [],
      selectedRoutes: [],
      selectedTeams: [],
      selectedPads: [],
      selectedAreas: [],
      selectedWaitingOn: [],
      selectedWorkTicketStatus: [],
      selectedWorkTicketCategories: [],
      selectedWorkTicketTypes: [],
      selectedWorkTicketPriorities: [],
      cycleTimeGreaterThanFilter: null,
      cycleTimeLessThanFilter: null,
      unitsGreaterThanFilter: null,
      unitsLessThanFilter: null,
      defermentPercentGreaterThanFilter: null,
      defermentPercentLessThanFilter: null,
      selectedSortType: VERTICAL_LIST_SORT_DETAULT_VALUE,
    })
  }) filteringDetails!: any;
  
  working: any = {}
  saveAsCustomFilter: boolean = false
  workingFilterId: string = ''
  workingFilterName: string = ''
  loading: boolean = false

  get isLoadingTasqs() {
    return tasqsListModule.isLoadingTasqs;
  }

  get user() {
    return workflowModule.user;
  }

  get showingTotalResultsValue() {
    const records = this.applyFilteringOnTasqs(
      this.getAllTasqs(this.working.selectedRoutes, this.working.selectedAreas, this.working.selectedPads), this.tasqListLevel, this.working
    )
    return records.length
  }

  get isWorkTicketView() {
    return this.tasqListLevel === 'WorkTicket';
  }

  get isPadView() {
    return this.tasqListLevel === 'Pad';
  }

  get tasqListLevel() {
    return tasqsListModule.tasqListLevel;
  }

  get workTicketJobCategories() {
    return this.workTicketCategories.map((item) => item.Name).sort();
  }

  get workTicketJobTypes() {
    const jobTypes = this.workTicketCategories.map((item) => item.Options).flat();
    if (jobTypes) {
      return [...new Set(jobTypes)].sort();
    }
    return [];
  }

  get workTicketCategories() {
    return JSON.parse(getConfigEnv('WORK_TICKET_JOB_CATEGORIES'));
  }

  get allTypes() {
    return tasqsListModule.allPredictionTypes;
  }

  get areas() {
    return scheduleModule.areas;
  }

  get enableRoutes() {
    const routes = scheduleModule.enabledRoutes.map((r) => r.Route);
    routes.sort((a, b) => {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    });
    return routes;
  }

  get teams() {
    return workflowModule.teams;
  }

  get enabledPads() {
    return assetsModule.enabledPads.map((r) => r.wellName);
    
  }

  get availableUsers() {
    return accountModule.reassignmentList
      .filter((u) => u && u.email)
      .map((i) => ({
        // @ts-ignore
        text: i.name || 'Test User',
        value: i.email || '',
      }))
      .sort((a, b) => a.text.localeCompare(b.text));
  }

  get textSearchValue() {
    return tasqsListModule.textSearchValue;
  }

  get isMobileView() {
    return tasqsListModule.isMobileView;
  }

  get completedTasqs() {
    return tasqsListModule.completedTasqList;
  }

  get filteringOptions() {
    return [
      {
        showCondition: this.isWorkTicketView,
        selectedValues: this.working.selectedWorkTicketStatus,
        eventKey: 'selectedWorkTicketStatus',
        options: ['Open', 'In Progress', 'Blocked', 'Complete'],
        title: 'Status',
        placeholder: 'ALL STATUS',
      },

      {
        showCondition: this.isWorkTicketView,
        selectedValues: this.working.selectedWorkTicketCategories,
        eventKey: 'selectedWorkTicketCategories',
        options: this.workTicketJobCategories,
        title: 'Categories',
        placeholder: 'ALL CATEGORIES',
      },

      {
        showCondition: this.isWorkTicketView,
        selectedValues: this.working.selectedWorkTicketTypes,
        eventKey: 'selectedWorkTicketTypes',
        options: this.workTicketJobTypes,
        title: 'Type',
        placeholder: 'ALL TYPES',
      },

      {
        showCondition: this.isWorkTicketView,
        selectedValues: this.working.selectedWorkTicketPriorities,
        eventKey: 'selectedWorkTicketPriorities',
        options: ['Highest', 'High', 'Medium', 'Low', 'Lowest'],
        title: 'Priority',
        placeholder: 'ALL PRIORITIES',
      },

      {
        showCondition: true,
        selectedValues: this.working.selectedAreas,
        eventKey: 'selectedAreas',
        options: this.areas,
        title: 'Area',
        placeholder: 'ALL Areas',
      },

      {
        showCondition: true,
        selectedValues: this.working.selectedRoutes,
        eventKey: 'selectedRoutes',
        options: this.enableRoutes,
        title: 'Route',
        placeholder: 'ALL ROUTES',
      },
      {
        showCondition: true,
        selectedValues: this.working.selectedTeams,
        eventKey: 'selectedTeams',
        options: this.teams,
        title: 'Team',
        placeholder: 'ALL TEAMS',
      },
      {
        showCondition: !this.isPadView && !this.isWorkTicketView,
        selectedValues: this.working.selectedWaitingOn,
        eventKey: 'selectedWaitingOn',
        options: this.waitingOnOptions,
        title: 'Waiting On',
        placeholder: 'ALL WAITING ON',
      },

      {
        showCondition: !this.isPadView,
        selectedValues: this.working.selectedUsernames,
        eventKey: 'selectedUsernames',
        options: this.availableUsers,
        title: 'Assigned To',
        trackBy: 'value',
        label: 'text',
        placeholder: 'ALL ASSIGNEES',
      },
      {
        showCondition: !this.isPadView && !this.isWorkTicketView,
        selectedValues: this.working.typesOptions,
        eventKey: 'typesOptions',
        options: this.allTypes,
        title: 'Job Types',
        placeholder: 'All JOB TYPES',
      },
      {
        showCondition: !this.isPadView,
        selectedValues: this.working.selectedPads,
        eventKey: 'selectedPads',
        options: this.enabledPads,
        title: 'Pad',
        hideSelectAllOptions: true,
        placeholder: 'All PADS',
      },
    ];
  }

  get activeFilter() {
    return tasqsListModule.activeFilterByPage(this.$route.name ?? '');
  }

  getTasqs(selectedRoutes=null, selectedAreas=null, selectedPads=null) {
    return tasqsListModule.tasqs(selectedRoutes, selectedAreas);
  }

  getAllTasqs(selectedRoutes=null, selectedAreas=null, selectedPads=null) {
    let allTasqs: any = this.getTasqs(selectedRoutes, selectedAreas,selectedPads);
    if (!this.isMobileView && this.textSearchValue.length && !this.isWorkTicketView) {
      allTasqs = [...allTasqs, ...this.completedTasqs];
    }
    return allTasqs
  }

  validateNameUniqueness () {
    return this.user.customFilters.findIndex(customFilter => {
      if (this.workingFilterId) {
        return customFilter.id !== this.workingFilterId && customFilter.name === this.workingFilterName && customFilter.level === this.tasqListLevel
      } else {
        return customFilter.name === this.workingFilterName && customFilter.level === this.tasqListLevel
      }
    });
  }

  async validateForm () {
    const { valid } = await (this.$refs.customFilterNameField as any).validate();
    if (!valid) {
      return false
    }

    const index = this.validateNameUniqueness()
    if (index !== -1) {
      // @ts-ignore
      this.$refs.customFilterNameField.setErrors(['Filter name should be unique.']);
      return false
    }

    return true
  }

  async handleApply () {
    if (this.saveAsCustomFilter) {

      const formValid = await this.validateForm()
      if (!formValid) {
        return
      }

      this.loading = true
      const savedFilter: any = await userProfileModule.saveCustomFilter(
        {
          filterId: this.workingFilterId,
          filterName: this.workingFilterName,
          filterLevel: this.tasqListLevel,
          pageName: this.$route.name,
          filterSettings: JSON.stringify(this.working)
        }
      )
      this.workingFilterId = savedFilter.id

    }
    this.setFilteringValuesInStore();
    let filteredData = this.applyFilteringOnTasqs(this.getAllTasqs(), this.tasqListLevel, this.activeFilter.settings);
    this.$emit('records-filtered', {results: filteredData, sortBy: this.working.selectedSortType})
    this.$emit('show-filters-popup-updated', false)
    this.loading = false
  }

  resetAll() {
    this.working.typesOptions = [];
    this.working.selectedUsernames = [];
    this.working.selectedRoutes = [];
    this.working.selectedTeams = [];
    this.working.selectedAreas = [];
    this.working.selectedWaitingOn = [];
    this.working.selectedWorkTicketStatus = [];
    this.working.selectedWorkTicketCategories = [];
    this.working.selectedPads = [];
    this.working.selectedWorkTicketTypes = [];
    this.working.selectedWorkTicketPriorities = [];
    this.working.cycleTimeGreaterThanFilter = null;
    this.working.cycleTimeLessThanFilter = null;
    this.working.unitsGreaterThanFilter = null;
    this.working.unitsLessThanFilter = null;
    this.working.defermentPercentGreaterThanFilter = null;
    this.working.defermentPercentLessThanFilter = null;
    this.saveAsCustomFilter = false;
    this.working.selectedSortType = VERTICAL_LIST_SORT_DETAULT_VALUE;
    this.handleApply()
  }

  /**
   * This is a helper method that doesn't use `this` context anywhere and placed
   * inside this component as logically it belongs here and is not used globally.
   * We can move it to utils or vuex actions in the future if required in multiple places.
   * 
   * Note: Do not use `this` inside it otherwise we wouldn't be able to use it without
   * rendering the whole component. Currently we can access it
   * 
   * import VerticalListFiltersAndSort from '@/components/tasqsCommon/VerticalListFiltersAndSort.vue';
   * results = VerticalListFiltersAndSort.options.methods.applyFilteringOnTasqs(tasqs, listLevel, filterSettings);
   */
  applyFilteringOnTasqs(tasqs, listLevel, filter) {
    let results = tasqs;

    if (!filter || Object.keys(filter).length === 0) {
      return results
    }

    if (listLevel !== 'WorkTicket') {
      if (listLevel === 'Pad') {
        if (filter.selectedWaitingOn && filter.selectedWaitingOn.length) {
          // results = results.filter((t) => filter.selectedWaitingOn.includes(t.waitUntilReason));
        }
      } else if (filter.selectedWaitingOn && filter.selectedWaitingOn.length) {
        results = results.filter((t) =>
          filter.selectedWaitingOn.find((status) => {
            if (status === 'None') {
              return t.waitUntilReason === '';
            }
            return (
              t.waitUntilReason &&
              t.waitUntilReason.split('-Remarks:')[0] &&
              status.toLowerCase() === t.waitUntilReason.split('-Remarks:')[0].trim().toLowerCase()
            );
          })
        );
      }
    }

    if (filter.selectedTeams && filter.selectedTeams.length) {
      if (listLevel === 'Pad') {
        // @ts-ignore
        results = results.filter((t) => !!t.wells.find((well) => filter.selectedTeams.includes(well.team)));
      } else {
        // @ts-ignore
        results = results.filter((t) => filter.selectedTeams.includes(t.team));
      }
    }

    if (filter.selectedPads && filter.selectedPads.length) {
      if (listLevel !== 'Pad'){
        // @ts-ignore
        results = results.filter((t) => filter.selectedPads.includes(t.padName));
      }
    }

    if (filter.selectedAreas && filter.selectedAreas.length) {
      // @ts-ignore
      if (listLevel === 'Pad') {
        // @ts-ignore
        results = results.filter((t) => !!t.wells.find((well) => filter.selectedAreas.includes(well.area)));
      } else {
        // @ts-ignore
        results = results.filter((t) => t.area && filter.selectedAreas.includes(t.area));
      }
    }

    if (filter.selectedRoutes && filter.selectedRoutes.length) {
      // @ts-ignore
      if (listLevel === 'Pad') {
        // @ts-ignore
        results = results.filter((t) => !!t.wells.find((well) => filter.selectedRoutes.includes(well.route)));
      } else {
        // @ts-ignore
        results = results.filter((t) => t.area && filter.selectedRoutes.includes(t.route));
      }
    }

    if (listLevel !== 'Pad' && listLevel !== 'WorkTicket') {
      if (filter.typesOptions && filter.typesOptions.length) {
        results = results.filter(
          (t) =>
            filter.typesOptions.includes(t.predictionType) ||
            filter.typesOptions.includes(`${t.predictionType} ${t.highLP}`)
        );
      }

      if (filter.selectedUsernames) {
        const usernames = filter.selectedUsernames.map((u) => u.value)
        if (usernames.length) {
          results = results.filter((t) => usernames.includes(t.username));
        }
      }

      if (filter.unitsGreaterThanFilter) {
        results = results.filter((t) => t.defermentValue && t.defermentValue > filter.unitsGreaterThanFilter);
      }

      if (filter.unitsLessThanFilter) {
        results = results.filter((t) => t.defermentValue && t.defermentValue < filter.unitsLessThanFilter);
      }

      if (filter.defermentPercentGreaterThanFilter) {
        results = results.filter(
          (t) => t.defermentPercentage && t.defermentPercentage > filter.defermentPercentGreaterThanFilter
        );
      }

      if (filter.defermentPercentLessThanFilter) {
        results = results.filter(
          (t) => t.defermentPercentage && t.defermentPercentage < filter.defermentPercentLessThanFilter
        );
      }

      if (filter.cycleTimeGreaterThanFilter) {
        results = results.filter((t) => t.deferringDays && t.deferringDays > filter.cycleTimeGreaterThanFilter);
      }

      if (filter.cycleTimeLessThanFilter) {
        results = results.filter((t) => t.deferringDays && t.deferringDays < filter.cycleTimeLessThanFilter);
      }
    }

    if (listLevel === 'WorkTicket') {
      if (filter.selectedWorkTicketCategories && filter.selectedWorkTicketCategories.length) {
        results = results.filter((t) => t.jobCategory && filter.selectedWorkTicketCategories.includes(t.jobCategory));
      }

      if (filter.selectedWorkTicketTypes && filter.selectedWorkTicketTypes.length) {
        results = results.filter((t) => t.jobType && filter.selectedWorkTicketTypes.includes(t.jobType));
      }

      if (filter.selectedWorkTicketStatus && filter.selectedWorkTicketStatus.length) {
        results = results.filter((t) => t.status && filter.selectedWorkTicketStatus.includes(t.status));
      }

      if (filter.selectedWorkTicketPriorities && filter.selectedWorkTicketPriorities.length) {
        results = results.filter((t) => t.priority && filter.selectedWorkTicketPriorities.includes(t.priority));
      }

      if (filter.selectedUsernames) {
        const usernames = filter.selectedUsernames.map((u) => u.value)
        if (usernames.length) {
          results = results.filter((t) => usernames.includes(t.username));
        }
      }
    }

    return results;
  }

  /**
   * This is a helper method that doesn't use `this` context anywhere and placed
   * inside this component as logically it belongs here and is not used globally.
   * We can move it to utils or vuex actions in the future if required in multiple places.
   * 
   * Note: Do not use `this` inside it otherwise we wouldn't be able to use it without
   * rendering the whole component. Currently we can access it
   * 
   * import VerticalListFiltersAndSort from '@/components/tasqsCommon/VerticalListFiltersAndSort.vue';
   * results = VerticalListFiltersAndSort.options.methods.sortTasqs(tasqs, listLevel, sortBy);
   */
  sortTasqs(tasqs, listLevel, sortBy=VERTICAL_LIST_SORT_DETAULT_VALUE) {
    const results = tasqs;
    let sortKey: any = 'defermentValue';
    if (sortBy === 'deferment-desc') {
      sortKey = 'defermentValue';
      if (listLevel === 'Pad') {
        sortKey = 'padDeferment';
      }
      return sort(results).desc(sortKey);
    }

    if (sortBy === 'deferment-asc') {
      sortKey = 'defermentValue';
      if (listLevel === 'Pad') {
        sortKey = 'padDeferment';
      }
      return sort(results).asc(sortKey);
    }

    if (sortBy === 'cycleTime-asc') {
      sortKey = 'dateAgoValue';
      return sort(results).asc(sortKey);
    }

    if (sortBy === 'cycleTime-desc') {
      sortKey = 'dateAgoValue';
      return sort(results).desc(sortKey);
    }

    if (sortBy === 'defermentPercent-asc') {
      sortKey = 'defermentPercentage';
      return sort(results).asc(sortKey);
    }

    if (sortBy === 'defermentPercent-desc') {
      sortKey = 'defermentPercentage';
      return sort(results).desc(sortKey);
    }

    if (sortBy === 'wellName-asc') {
      if (listLevel === 'Well' || listLevel === 'WellView' || listLevel === 'WorkTicket') {
        sortKey = 'wellName';
        return sort(results).asc(sortKey);
      }
      sortKey = 'padName';
      return sort(results).asc(sortKey);
    }

    if (sortBy === 'wellName-desc') {
      if (listLevel === 'Well' || listLevel === 'WellView' || listLevel === 'WorkTicket') {
        sortKey = 'wellName';
        return sort(results).desc(sortKey);
      }
      sortKey = 'padName';
      return sort(results).desc(sortKey);
    }

    if (sortBy === 'actioned-desc') {
      sortKey = 'lastActionTime';
      return sort(results).desc(sortKey);
    }

    if (sortBy === 'actioned-asc') {
      sortKey = 'lastActionTime';
      return sort(results).asc(sortKey);
    }

    if (sortBy === 'priority-desc') {
      sortKey = 'priorityLevel';
      return sort(results).desc(sortKey);
    }

    if (sortBy === 'priority-asc') {
      sortKey = 'priorityLevel';
      return sort(results).asc(sortKey);
    }

    return results;
  }

  setFilteringValuesInStore() {
    this.working.selectedUsernames = this.working.selectedUsernames.filter((u) => u && u.value);
    tasqsListModule.setActiveFilter({
      id: this.workingFilterId,
      name: this.workingFilterName,
      settings: this.working
    });
  }

  cancel() {
    this.$emit('show-filters-popup-updated', false)
  }

  initFilteringValues () {
    this.working = JSON.parse(JSON.stringify(this.filteringDetails))
  }

  @Watch('filteringDetails', {
      immediate: true,
  })
  onFilteringDetailsChange(value, oldValue) {
    this.initFilteringValues();
  }

  @Watch('showFiltersPopup')
  onShowFiltersPopupChange(value, oldValue) {
    if (value === true) {
      this.loading = false
      this.workingFilterId = this.filterId
      this.workingFilterName = this.filterName
      this.saveAsCustomFilter = false
      if (this.workingFilterId) {
        this.saveAsCustomFilter = true
      }
      this.initFilteringValues()
    }
  }
}
